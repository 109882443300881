@import '../config';

.ant-message .ant-message-notice-content {
  font-size: 16px;
  font-weight: bold;
  padding: 16px 24px;
  border-radius: 24px;
  max-width: 40vw;
}

.ant-message .ant-message-notice-content .anticon {
  font-size: 22px;
  padding-right: 8px;
}

.ant-message .ant-message-notice-content .ant-message-custom-content.ant-message-error {
  display: flex;
  align-items: center;
}

.error-message {
  color: #091817;
}

.report-error {
  cursor: pointer;
  padding: 0px 5px 2px;
  color: $base_color;
  border-bottom: 1px solid $base_color;
  transition:
    color 0.3s,
    border-color 0.3s;
}

.report-error:hover {
  color: #2b8c84;
  border-bottom: 1px solid #2b8c84;
}

@media screen and (max-width: 768px) {
  .ant-message .ant-message-notice-content {
    font-weight: normal;
    max-width: 90vw;
  }
}
