@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../_config.scss';

.AdminPersonCard {
  // width: 360px;
  // width: 30%;

  height: 100px;
  border-radius: 100px;
  background-color: #fff;
  // width: 30%;
  margin: 0 1.66%;
  margin-bottom: 15px;
}
.AdminPersonCardImage {
  img {
    float: left;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin: 19px;
    margin-right: 12px;
  }
}

.AdminPersonCardImageName {
  font-size: 17px;
  color: green;
  text-align: left;
  margin: 0;
  padding-top: 20px;
  font-weight: 600;
  color: $base_color;
}

.AdminPersonCardImageInfo {
  font-size: 17px;
  color: grey;
  text-align: left;
  margin: 5px;
  font-weight: 600;
}

.AdminPersonCardArrow {
  float: right;
  position: relative;
  top: -47px;
  right: 15px;
  font-size: 35px;
}

.LeaveDate {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // bottom: 15px;
}

@media screen and (max-width: 768px) {
  .AdminPersonCard {
    width: 85%;
    height: 80px;
    border-radius: 100px;
    background-color: #fff;
    margin: auto;
    top: 90px;
    margin-bottom: 15px;
  }
  .AdminPersonCardImage {
    float: left;
    border-radius: 50%;
    height: 50px;
    margin: -7px;
  }

  .AdminPersonCardImageName {
    font-size: 015px;
    color: green;
    text-align: left;
    margin: 0;
    padding-top: 20px;
    font-weight: 600;
    color: $base_color;
  }

  .AdminPersonCardImageInfo {
    font-size: 15px;
    color: grey;
    text-align: left;
    margin: 0;
    font-weight: 600;
    margin-left: 78px;
  }

  .AdminPersonCardArrow {
    /* ORIGINAL */
    // float: right;
    // position: absolute;
    // top: 25px;
    // right: 15px;
    // font-size: 25px;

    float: right;
    position: relative;
    top: -35px;
    right: 15px;
    font-size: 25px;
  }
}
