@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.clientsActivities {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', arial, serif;
  font-weight: bold;
  overflow: scroll;
}

.clientHeader {
  background: black;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  font-size: 1.1rem;
}

.clientDate {
  width: 50%;
  color: $base_color;
  margin: 0 auto 10px;
  text-align: center;
}

.clientDate b {
  font-size: 1.5em;
}

.clientDate div {
  display: block;
  text-transform: capitalize;
  font-weight: 100;
}

.clientTitle {
  width: 80vw;
  color: $base_color;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.comment {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 0;
}

.comment span {
  display: block;
  width: 80vw;
  color: $base_color;
  margin: 0 auto;
  text-align: center;
  font-size: 1em;
}

.activities {
  height: calc(100% - 200px);
  width: 85%;
  margin: auto;
}

.cancel-action-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.action-deletion-dropdown {
  &.deactivation-specification {
    margin-top: unset;
    width: 100%;
  }
}

.cancel-action-description.ant-input.ant-input-outlined {
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
}

.activityHeader {
  display: flex;
  justify-content: space-around;
  h1 {
    font-size: 1.8em;
  }
  i {
    margin-top: -3px;
  }
}

.sideIcon.anticon {
  float: right;
  cursor: pointer;
}

.dropdownHeadline {
  font-size: 16px;
}

.dropdownHeadline:hover {
  color: $base_color;
  cursor: pointer;
}

.cursorPointer:hover {
  cursor: pointer;
}

.activitiesSign {
  font-size: large;
  margin-left: 20px;
}

.dropdownHeadline div,
.activityName {
  display: inline-block;
}

.keyos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.activeDropdownHeadline * {
  color: $base_color;
}

.dropdownActivities {
  margin-bottom: 10px;
}

// Hidden scrollbar
.activities::-webkit-scrollbar,
.dropdownActivities::-webkit-scrollbar {
  width: 0;
}

.intervalActivities {
  border-bottom: 1px solid $base_color;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.activityName {
  color: $base_color;
  cursor: pointer;
}

.monthWeek {
  color: black;
  cursor: pointer;
}

.bottomButtons {
  width: 100%;
  height: 50px;
}

.clientButtons {
  margin: 9px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  button {
    margin: 0 8px;
    color: white;
    background: $base_color;
    font-size: 0.95em;
    border: 0;
    padding: 0 25px;
  }

  button.ant-btn:focus {
    color: white;
  }
  button.ant-btn:hover {
    color: $base_color;
  }
}

.clientButtonsFixed {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 10;
  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;

  button {
    margin: 0 8px;
    color: white;
    background: $base_color;
    font-size: 0.95em;
    border: 0;
    padding: 0 25px;
  }

  button.ant-btn:focus,
  button.ant-btn:hover {
    color: $base_color;
  }
}

.activity_show {
  display: block;
}

.activity_hide {
  display: none;
}

.activities-button {
  background-color: white;
  color: $base_color;
  font-size: 0.95em;
  margin-left: 10px;
  border: 2px solid $base_color;
}

.activities-button:hover,
.activities-button:focus {
  background-color: lighten($color: $base_color, $amount: 20%);
  color: white;
}

.clickedButton {
  background-color: lighten($color: $base_color, $amount: 20%);
  color: white;
  font-size: 0.95em;
  margin-left: 10px;
  border: 2px solid $base_color;
}

.clickedButton:hover,
.clickedButton:focus {
  background-color: lighten($color: $base_color, $amount: 20%);
  color: white;
  font-size: 0.95em;
  margin-left: 10px;
  border: 2px solid $base_color;
}

.actionCloseIcon {
  position: absolute;
  float: right;
  right: 10px;
  color: #41aea6;
  font-size: 2em;
}

.actionHeader {
  padding: 11px;
  font-size: 14px;
  .missingClientWarning {
    color: #d13c3c;
  }
}

.clientDropdown {
  margin: 0 5px;
  color: white;
  background: $base_color;
  font-size: 1em;
  padding: 4.7px 10px;
  border-radius: 15px;
  font-weight: 100;
  div {
    background-color: #41aea6;
    color: white;
    display: inline-block;
  }
}

.client-pickerTitle {
  font-weight: bold;
  // float: left;
  text-align: center;
  color: $base_color;
  margin: 5px 0;
}

.client-changeDate {
  h3 {
    float: left;
    margin-bottom: 2px;
    color: #41aea6;
  }
  .ant-time-picker,
  .ant-input {
    width: 230px;
  }
  .ant-time-picker-input {
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
    width: 100%;
  }
  // .client-picker{
  //   display: block;
  // }
}

.activityPicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .anticon-left,
  .anticon-right {
    color: $base_color;
  }
}

.openDevStageModal {
  cursor: pointer;
}

.openDevStageModal:hover {
  text-decoration: underline;
}

.devStageTooltipIcon {
  padding-left: 5px;
  margin-top: -3.5px;
}

.actionClientInfo {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .clientButtons {
    flex-direction: column;
    align-items: center;
    .clientDropdown,
    button {
      margin: 5px 0;
      display: inline-block;
    }
  }

  .actionClientInfo {
    padding-top: 15px;
  }
}
