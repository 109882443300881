.ActionDetailAdmin-main {
  top: 0;
  left: 0;
  z-index: 800;
  background-color: white;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', arial, serif;
  font-weight: bold;
  overflow: auto;
}

.ActionDetailAdmin-tempPhotos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.ActionDetailAdmin-photo {
  margin: 5px 1.5%;
}

#ActionDetailAdmin-modalGallery img {
  width: 100%;
  position: relative;
  padding: 60px 20px 5px;
}

.ActionDetailAdmin-modalGallery {
  .ant-modal-body {
    padding: 10px;
  }
}

.editActionAdmin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editActionAdmin > label {
  margin: 20px 0 5px 0;
}

.editActionAdmin > label:first-of-type {
  margin-top: 0;
}

.admin-input {
  width: fit-content;
}

.action-detail-modal-buttons {
  padding-top: 30px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.editButtons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.editButton {
  margin: 10px 0;
}

.openDevStageModal {
  cursor: pointer;
}

.openDevStageModal:hover {
  text-decoration: underline;
}

.devStageTooltipIcon {
  padding-left: 5px;
  margin-top: -3.5px;
}

.actionClientInfo {
  display: flex;
  justify-content: center;
}

.action-detail-evaluation {
  padding-bottom: 15px;
}

.action-detail-comment {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.action-detail-edit-comment.ant-input.ant-input-outlined {
  border-radius: 8px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .actionClientInfo {
    padding-top: 15px;
  }
}
