@import url('https://fonts.googleapis.com/css?family=Montserrat');

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  padding: 5px;
  background-color: #272928;
  position: fixed;
  top: 0; /* Position the navbar at the top of the page */
  width: 100vw;
}

.logoheader {
  img {
    margin-left: 15px;
    width: 40px;
    border-radius: 50%;
  }
}

.header-headline {
  font-family: 'Montserrat', arial;
  color: white;
  margin: 0;
  font-size: 20px;
}

.menu {
  margin-right: 15px;
  width: 50px;
}

.burger {
  height: 40px;
  padding: 15% 15%;
}

.header-rightSide {
  display: flex;
  align-items: center;
}

.notification {
  display: flex;
  position: relative;
  padding-right: 5px;
  &-icon {
    height: 23px;
    width: 23px;
  }
  &-badge {
    position: absolute;
    top: -5px;
    right: -2px;
    height: 20px;
    width: 20px;
    border-radius: 50% 50%;
    background-color: rgb(204, 34, 34);
    text-align: center;
    font-family: 'Montserrat', arial;
    color: white;
    font-size: 13px;
    font-weight: bold;
    line-height: 15px;
    border: 2px solid #272928;
  }
}
