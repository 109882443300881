@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

input {
  border: 2.5px solid #41aea6;
  border-radius: 36px;
  outline: none;
  color: #9999a0;
  font-weight: bold;
  font-size: 16px !important;
  height: 38px;
  padding: 4px 11px;
  cursor: text;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;

  ::placeholder {
    text-overflow: ellipsis;
    color: #9999a0;
  }
}

.suggestions-container {
  margin-top: 4px;
  border: 2.5px solid #41aea6;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: gray;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  width: 100%;

  .suggestions {
    margin-bottom: 0;
    padding-left: 0;
    max-height: 400px;
    list-style: none;
    overflow-y: scroll;
    background-color: white;

    li {
      padding: 0.5rem;
      text-align: left;
    }

    li:hover {
      background-color: #41aea6;
      color: white;
      cursor: pointer;
    }
  }
}

.no-suggestions {
  text-align: center;
}
