@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.mainBox {
  height: 100%;
}

.addNewActionPicker {
  width: 230px;
  margin: 0 auto;
  h3 {
    float: left;
    margin-bottom: 2px;
    color: #41aea6;
  }
  .ant-time-picker {
    width: 230px;
  }
  .ant-time-picker-input {
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }
  /*#timeSpent {
    width: 50px;
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }*/
}
.addNewAction-spentTimePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-weight: bold;
    margin: 10px 5px;
    color: #41aea6;
  }
  .ant-time-picker {
    width: 230px;
  }
  .ant-time-picker-input {
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }
  #timeSpent {
    width: 80px;
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }
}

.pridatContainer {
  .anticon {
    color: $base_color;
    vertical-align: 0;
  }
}

.actionsDropdown {
  margin: 0 auto;
  font-size: 18px;
  font-family: 'Montserrat', arial;
  color: $base_color;
  font-weight: bold;
  text-align: center;
}

.ant-input.picker {
  border-color: $base_color;
  border-width: 2px;
  border-radius: 36px;
  padding: 4px 0px 4px 23px;
  box-shadow: none;
}

.pickerHeader {
  margin: 0 auto;
  text-align: center;
  color: $base_color;
}

.actionHeader {
  margin: 0 auto;
  text-align: center;
  color: $base_color;
  padding-bottom: 8px;
  font-size: 1.2em;
  font-weight: bold;
}

table {
  margin: auto;
}

.pickers {
  padding: 0px 10px;
}

.addButton {
  font-family: 'Montserrat', arial;
  background: $base_color;
  color: white;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  border: 0;
  height: 40px;
  font-size: 18px;
  padding: 0 40px;
  border-radius: 32px;
}

.dropdownSelect {
  .ant-dropdown-trigger {
    max-width: 230px;
    border: 2px solid $base_color;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 32px;
  }
}

.selectRow {
  color: white;
  &.ant-select-dropdown-menu-item-selected {
    color: $base_color;
  }
}

.selectMenu {
  background-color: $base_color;
}

.selectRow:hover {
  color: $base_color;
}

.SelectInputMulti {
  width: 230px;
  display: block;
  margin: 0 auto;
  > div {
    border: none;
    padding-left: 17px;
    border: 2px solid $base_color;
    border-radius: 32px;
  }
}

.ant-dropdown-menu-item {
  background-color: white;
  color: $base_color;
}

.ant-dropdown-menu-item-active {
  color: $base_color;
}

.ant-dropdown-menu-item-selected {
  color: $base_color;
}

.ant-dropdown-menu {
  background-color: $base_color;
  max-height: 400px;
  overflow-x: scroll;

  li a {
    display: block;
    width: 100%;
  }
}

.ant-menu-item-selected {
  background-color: $base_color;
  color: white;
}

.typeDropdown {
  .ant-dropdown-trigger {
    max-width: 200px;
  }
}

.activityFormField {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 65%;
  padding: 10px;
}

.action-group-title .ant-dropdown-menu-item-group-title {
  color: rgba(255, 255, 255, 0.65);
}

.actionItem {
  list-style-type: none;
}
