@import '../config';

.desktopView {
  display: block;
}

.mobileView {
  display: none;
}

.interNDA-score-table {
  max-width: 720px;
  text-align: center;
  margin: auto;
  padding: 20px;
  font-weight: bold;
}

.interNDA-score-table .ant-descriptions-view {
  border: 1px solid $base_color;
  border-radius: 10px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.interNDA-score-table.show-in-milestone .ant-descriptions-view {
  border: 1px solid #e6e5e5;
  box-shadow: none;
}

.interNDA-score-table.show-in-milestone .ant-descriptions-row {
  border-bottom: 1px solid #e6e5e5;
}

.interNDA-score-table.show-in-milestone .ant-descriptions-row:last-child {
  border-bottom: none;
}

.interNDA-score-table.show-in-milestone .ant-descriptions-item-label,
.interNDA-score-table.show-in-milestone .ant-descriptions-item-content {
  border-right: 1px solid #e6e5e5;
}

.interNDA-score-table.show-in-milestone .ant-descriptions-item-label:last-child,
.interNDA-score-table.show-in-milestone .ant-descriptions-item-content:last-child {
  border-right: none;
}

.interNDA-score-table.show-in-milestone .ant-descriptions-item-label {
  color: #091817;
  font-weight: bold;
}

.interNDA-score-table .ant-descriptions-row {
  border-bottom: 1px solid $base_color;
  background-color: white;
}

.interNDA-score-table .ant-descriptions-item-label {
  color: $base_color;
  font-weight: bold;
}

.interNDA-score-table .ant-descriptions-item-label,
.interNDA-score-table .ant-descriptions-item-content {
  border-right: 1px solid $base_color;
}

.interNDA-score-table .ant-divider-horizontal {
  margin: 8px 0;
}

.interNDA-score-table .low-risk {
  color: #12c99b;
}

.interNDA-score-table .moderate-risk {
  color: #f2a600;
}

.interNDA-score-table .high-risk {
  color: #e41749;
}

.show-in-milestone {
  padding: 15px 0;
}

@media screen and (max-width: 768px) {
  .desktopView {
    display: none;
  }
  .mobileView {
    display: block;
  }
  .interNDA-score-table {
    padding: 20px 0;
    font-size: 12px;
    font-weight: normal;
  }
  .interNDA-score-table .ant-descriptions {
    padding-bottom: 20px;
  }
  .interNDA-score-table .ant-descriptions:last-child {
    padding-bottom: 0px;
  }
  .interNDA-score-table .ant-descriptions-title {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .interNDA-score-table .ant-descriptions-item-label,
  .interNDA-score-table .ant-descriptions-item-content {
    padding: 12px 16px;
    font-size: 12px;
    font-weight: normal;
  }
  .show-in-milestone .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
  .show-in-milestone .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 8px 8px;
  }
}
