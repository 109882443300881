@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../_config.scss';

.clientsScreen {
  text-align: center;
  background-color: #272928;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.personCard {
  width: 85%;
  height: 100px;
  border-radius: 100px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin: auto;
  //border: 1px solid;
  position: relative;
  margin-bottom: 15px;
}
.personCardImage {
  img {
    float: left;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin: 19px;
    margin-right: 12px;
  }
}

.personCardImageName {
  font-size: 17px;
  color: green;
  text-align: left;
  margin: 0;
  padding-top: 16px;
  font-weight: 600;
  color: $base_color;
}

.personCardImageInfo {
  font-size: 17px;
  color: grey;
  text-align: left;
  margin: 5px;
  font-weight: 600;
}

.personCardArrow {
  float: right;
  position: absolute;
  top: 30px;
  right: 15px;
  font-size: 35px;
}

.greenArrow {
  color: $base_color;
}

@media screen and (max-width: 400px) {
  .personCard {
    width: 85%;
    height: 80px;
    border-radius: 100px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin: auto;
    top: 0;
    margin-bottom: 15px;
  }
  .personCardImage {
    float: left;
    border-radius: 50%;
    height: 50px;
    margin: -7px;
  }

  .personCardImageName {
    font-size: 015px;
    text-align: left;
    margin: 0;
    padding-top: 14px;
    font-weight: 600;
    color: $base_color;
  }

  .personCardImageInfo {
    font-size: 15px;
    color: grey;
    text-align: left;
    margin: 0;
    font-weight: 600;
    margin-left: 78px;
  }

  .personCardArrow {
    float: right;
    position: absolute;
    top: 25px;
    right: 15px;
    font-size: 25px;
  }
}
