.TODO_MAIN_NAME {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
  background-color: white;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', arial;
  font-weight: bold;
  overflow: scroll;
}

.clientProfileAction-main {
  padding: 0 10px;
  overflow-y: auto;
}

.clientProfileAction-headline {
  text-align: center;
  font-weight: bold;
  margin: 0 0 15px;
  padding-top: 15px;
  font-size: 1.25em;
  border-top: 1px solid #41aea6;
}

.clientProfileAction-activitiesList {
  padding-bottom: 10px;
  * .groupList {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
.clientProfileAction-comment {
  text-align: center;
}
