@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.backgroundMenu {
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: white;
  width: 100vw;
  height: 100vh;
  min-height: 750px;
}

.navigationDiv {
  text-align: center;
  padding-top: 6vh;
}

.displayDiv {
  display: inline-grid;
}

.navigationDiv p {
  text-align: left;
  font-size: 20px;
  font-family: 'Montserrat', arial;
  color: $base_color;
  font-weight: bold;
}

.logoff {
  margin-top: 5px;
}

.closeIcon.anticon {
  color: $base_color;
  font-size: 2em;
  float: right;
  margin-top: 3vh;
  margin-right: 5vw;
  cursor: pointer;
}
