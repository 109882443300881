@import '../config';

.progress-bar {
  margin-bottom: 12px;

  &-name {
    margin: 0 0 5px 0;
  }

  &-chart {
    display: flex;
    align-items: center;

    &-bar {
      display: flex;
      height: 14px;
      width: 100%;
      background: #e6e6e6;
      border-radius: 50px;
      overflow: hidden;

      &-fill {
        background: $base_color;
        height: 100%;
        position: relative;
        text-align: center;
        &.red {
          background: #ae4149;
        }
        &.orange {
          background: #f1c180;
        }
        &-percentage {
          word-break: normal;
          position: relative;
          vertical-align: middle;
          color: white;
          font-weight: 500;
          font-size: 12px;
          &.shifted {
            position: absolute;
            right: -20px;
            transform: translateX(50%);
            top: 1px;
            color: black;
          }
        }
      }
    }

    &-points {
      width: 60px;
      padding-left: 10px;
    }
  }
}
