@import '../../../config';

.preschool-screening-form-background {
  background-color: white;
  margin: 80px auto 0 auto;
  width: 100%;
}

.preschool-answer-dropdown {
  padding-left: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  .ant-dropdown-trigger {
    max-width: 230px;
    border: 2px solid $base_color;
    color: white;
    background-color: $base_color;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 32px;
    i {
      position: relative;
      top: -3px;
      right: -5px;
    }
  }
}

.preschool-screening-questions {
  padding-top: 10px;
}

.preschool-screening-questions h3 {
  font-size: 18px;
  padding-top: 20px;
}

.preschool-screening-questions .table {
  overflow: hidden;

  tr th {
    text-align: center;
  }
}

.preschool-screening-form-grid {
  display: grid;
  grid-row-gap: 50px;
  grid-column-gap: 60px;
  margin: 0 0 20px;
  &.col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.col-2 {
    grid-template-columns: 1fr 1fr;
  }
  &.measurement-date {
    padding-top: 10px;
  }
  .form-item {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

.preschool-screening-info-data {
  font-weight: 800;
  font-size: 18px;
}

.preschool-screening-info-input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
  text-align: center;
}

.preschool-screening-client-info .ant-descriptions-item-label {
  display: none;
}

.preschool-screening-client-info .choose-language {
  padding-top: 10px;
}

.preschool-screening-form-wrapper {
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 40px;
  text-align: center;
  h2 {
    padding: 0 0 30px;
    text-align: center;
    margin: 0 auto;
    color: inherit;
    font-size: 30px;
  }
  .back-button {
    margin-top: 20px;
    font-size: 16px;
    color: #41aea6;
    font-weight: bold;
  }
  textarea {
    font-size: 16px;
    background-color: white;
    border: 1px #41aea6 solid;
  }
  .ant-dropdown-menu-item {
    text-align: center;
  }
}

.preschool-screening-note {
  font-weight: normal;
  font-size: 14px !important;
}

.preschool-screening-info-label {
  margin: 10px 0px 10px 0;
  font-size: 16px;
  color: #41aea6;
  font-weight: bold;
}

.preschool-screening-footer {
  text-align: center;
  padding-top: 20px;
}

.preschool-screening-footer .save-measurement {
  border-color: $base_color;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: $base_color;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
}

.preschool-screening-footer .remove-measurement {
  border-color: #ae4141;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: #ae4141;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 20px;
}

.preschool-screening-month-picker .ant-calendar-picker-icon {
  top: 45%;
  color: $base_color;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .preschool-screening-client-info {
    padding-top: 20px;
  }

  .preschool-screening-form-grid {
    display: grid;
    grid-row-gap: 0;
    grid-column-gap: 60px;
    margin: 0 0 10px;
    &.col-4 {
      grid-template-columns: 1fr;
    }
    &.col-3 {
      grid-template-columns: 1fr;
    }
    &.col-2 {
      grid-template-columns: 1fr 1fr;
    }
    .form-item {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  .preschool-screening-form-background {
    margin: 50px auto 0 auto;
  }

  .preschool-screening-info-label {
    font-size: 14px;
  }

  .preschool-screening-info-data {
    font-size: 14px;
  }

  .preschool-screening-form-wrapper .back-button {
    font-size: 11px;
    font-weight: normal;
    margin-top: 12px;
    color: $base_color;
  }

  .preschool-screening-form-wrapper ._3Tv81 {
    margin: 0;
    padding: 0;
  }

  .preschool-screening-form-wrapper h2 {
    font-size: 18px;
    padding-bottom: 16px;
  }

  .preschool-screening-footer {
    padding-top: 20px;
  }

  .preschool-screening-footer .save-measurement {
    height: 35px;
    font-size: 14px;
    font-weight: normal;
  }

  .preschool-screening-footer .remove-measurement {
    height: 35px;
    font-size: 14px;
    font-weight: normal;
  }

  .preschool-screening-questions .activeStep {
    display: block;
  }

  .preschool-screening-questions .hiddenStep {
    display: none;
  }

  .preschool-screening-questions {
    padding-top: 0;
  }

  .preschool-screening-questions .ant-descriptions-item-label {
    display: none;
  }

  .preschool-screening-questions .ant-descriptions-item-content {
    text-align: left;
  }

  .preschool-screening-questions .button-next-icon {
    position: relative;
    top: 0;
    vertical-align: 0.1em;
  }

  .preschool-screening-questions h3 {
    font-size: 14px !important;
    padding-top: 0;
  }

  .preschool-screening-note {
    font-size: 12px !important;
  }

  .preschool-screening-questions-table-mobile {
    border: 1.5px solid rgb(65, 174, 166);
    border-radius: 16px;
    overflow: hidden;
  }

  .preschool-screening-month-picker .ant-input {
    font-size: 14px !important;
    text-align: center;
  }

  .preschool-screening-month-picker .ant-calendar-picker-icon {
    top: 45%;
    color: $base_color;
  }

  .preschool-answer-dropdown {
    font-size: 14px;
    font-weight: normal;
  }

  .preschool-screening-info-input {
    font-size: 14px !important;
  }
}
