@import '../../config';

@media screen and (min-width: 750px) {
  .admin-omama-bottompanel,
  .admin-generalUserInfo {
    overflow-x: hidden;
    width: unset;
  }
  .supervisorPlanMobileView {
    display: none;
  }
  .supervisorActionsDesktopView {
    display: block;

    // Sub Menu - Akcie
    .admin-supervisor-actions-week {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      color: $base_color;

      &-rangePicker {
        width: 65%;
        margin-right: 25px;
      }

      .admin-supervisor-actions-week__changeWeek {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1.1rem;

        * {
          display: flex;
        }
      }
    }

    .supervisor-actions-table {
      .ant-table-thead > tr > th:nth-child(1),
      .ant-table-thead > tr > th:nth-child(3) {
        min-width: 130px;
      }
      .ant-table-thead > tr > th:nth-child(2) {
        min-width: 80px;
      }
      .ant-table-thead > tr > th:nth-child(4),
      .ant-table-thead > tr > th:nth-child(6) {
        min-width: 100px;
      }
      .ant-table-thead > tr > th:nth-child(5) {
        min-width: 300px;
        width: 55%;
      }
      .anticon-delete {
        color: #8d8d8d;
        font-size: 20px;
      }
      tbody tr td:nth-child(5) > div {
        max-height: 75px;
        overflow: auto;
      }
      tbody tr.non-supervision {
        cursor: default;
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  .admin-supervisor-actions-week {
    &-rangePicker {
      margin-right: 0 !important;
    }
    .adminClient-button {
      margin-left: 5px;
    }
  }
  .monthLabel {
    display: none;
  }
  .monthData {
    text-align: center;
    min-width: 90px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1030px) {
  .admin-supervisor-actions-week {
    &-rangePicker {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 750px) {
  .supervisorActionsDesktopView {
    display: none;
  }
  .supervisorPlanMobileView {
    display: block;

    .container {
      padding-top: 0;

      &-inner {
        &-options {
          display: flex;
          justify-content: space-between;

          &-rangePicker {
            width: 55%;
          }

          &-monthPicker {
            display: flex;
            align-items: center;
            color: $base_color;
            width: 30%;
            margin-right: 10px;

            &-icon {
              position: relative;
              top: -2px;
            }

            &-text {
              text-align: center;
            }
          }

          &-addAction {
            width: 15%;
          }
        }

        &-toDoList {
          margin: 20px 10px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          height: calc(100vh - 120px - 40px);

          &-toDo {
            border-bottom: 1px solid $base_color;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            &-date {
              flex: 30%;
            }

            &-time {
              flex: 20%;
              color: $base_color;
            }

            &-title {
              flex: 45%;
            }

            &-arrow {
              flex: 5%;
              color: $base_color;
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}

.ant-select-dropdown-menu-item-active {
  color: #41aea6 !important;
}

.changeActionContainer {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.timeSpentEdit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.timeSpentInput {
  width: 8ch;
}

.editActionAdmin > p {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.editIcon {
  padding: 10px;
  cursor: pointer;
}

.adminClient-form-group {
  text-wrap: nowrap;
}
