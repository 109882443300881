@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.admin-main-clientProfile {
  display: flex;
  justify-content: space-between;
  &-inner {
    min-width: 180px;
    width: 25%;
    max-width: 250px;
  }
}

.admin-client-panel {
  width: 100%;
  background-color: white;
  padding: 15px 2%;
  border-radius: 25px;
  color: #091817;

  .desktopOnly {
    display: block;
  }

  .admin-client-photo img {
    border-radius: 50%;
    display: block;
    width: 174px;
    height: 174px;
    margin: 10px auto;
    border: 12px solid rgba(65, 174, 165, 0.15);
  }

  .admin-client-name {
    font-size: 24px;
    text-align: center;
  }

  .admin-client-region {
    margin: 3px 0;
    text-align: center;
  }

  .admin-client-city {
    margin: 3px 0;
    text-align: center;
  }

  .admin-client-name,
  .admin-client-date {
    display: block;
  }

  .admin-client-info {
    padding: 10px 20px;
  }

  .admin-client-info-data {
    padding-top: 10px;
  }

  .clientInfo-title {
    font-weight: bold;
  }
}

.clientBox {
  display: flex;

  .clientInfo {
    padding-top: 85px;

    .ant-descriptions {
      margin-top: 15px;
    }

    h2 {
      margin-top: 20px;
      font-size: 16px;
      color: #41aea6;
    }
  }
}

.clientInfo > h2 {
  color: #41aea6;
}

.clientMeasurement {
  padding-bottom: 20px;
  .interNDA-score-table {
    padding-top: 10px;
  }
}

.measurementTitleFirst {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  color: $base_color;
  padding-top: 10px;
  padding-bottom: 5px;
}

.measurementTitle {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  color: $base_color;
  padding-top: 20px;
  padding-bottom: 5px;
  border-top: 1px solid $base_color;
}

.noMeasurement {
  padding-bottom: 2px;
}

.showMeasurement {
  text-align: right;
  color: #41aea6;
}

.showMeasurement .anticon {
  vertical-align: 1px;
  padding-left: 4px;
  font-size: 12px;
}

.measurementDesktopTable {
  padding-top: 20px;
}

.submenu {
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: white;
  padding: 32px 20px;
  border-radius: 25px;
  margin-bottom: 25px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    li {
      cursor: pointer;
      a {
        transition: none;
        padding: 0 5px;
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
        color: #060606;
        transition: color 0.3s;
      }
    }

    li:hover a,
    li a.active,
    li:focus a {
      color: $base_color;
      padding-bottom: 3px;
      border-bottom: 2px solid $base_color;
      transition: color 0.3s;
    }
  }
}

.admin-clients-bottompanel {
  padding: 15px 20px;
  border-radius: 25px;
}

.admin-main-clientProfile .admin-rightside {
  //width: -webkit-fill-available;
  flex: 1;
  overflow-x: hidden;
  text-align: -webkit-center;
  padding-left: 25px;
  &-header {
    //width: calc((95vw - 70px) * 0.8);
    cursor: pointer;
  }
}

.ant-table-wrapper.table.clientDesktopView {
  overflow: hidden;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-clients-bottompanel div .imageGalleryMain {
  display: flex;
}
.admin-clients-bottompanel div .imageGalleryMain div .imageGallery {
  position: inherit;
  top: 0;
  padding-bottom: 0;
}

.admin-clients-bottompanel div .imageGalleryMain div .imageGallery img {
  height: auto;
  margin: 5px;
  width: 266px;
  border-radius: 5px;
}

.admin-clients-bottompanel
  .clientInfo
  .ant-descriptions.ant-descriptions-small.ant-descriptions-bordered
  .ant-descriptions-title {
  color: #41aea6;
  margin-top: 15px;
}

.admin-clients-bottompanel .clientInfo .ant-descriptions.ant-descriptions-bordered .ant-descriptions-title {
  color: #41aea6;
  margin-top: 15px;
}

.clientInfo .ant-descriptions-row .ant-descriptions-item-content {
  background-color: white;
}

.clientInfo .ant-descriptions-row .ant-descriptions-item-label {
  background-color: #fafafa !important;
}

.clientEditButtons {
  margin-bottom: 20px;
}

.clientEditButton {
  margin-top: 20px;
  width: 90%;
}

.admin-clients-bottompanel div ul {
  text-align: center;
  margin-top: 20px;
}

.adminClient-omama-col {
  width: 8vw;
  text-transform: capitalize;
}

.adminClient-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 16px;
  align-items: center;
}

.adminClient-form-group {
  display: flex;
  gap: 16px;
}

.adminClient-form-add-button {
  margin-left: auto;
}

.adminClient_datepicker {
  max-width: 350px;
}

.adminClient_datepicker .ant-calendar-picker {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
}

span.adminClient_datepicker.ant-calendar-picker span.ant-calendar-picker-input.ant-input {
  border-color: #060606;
  border-radius: 36px;
  border-width: 1px !important;
  color: #060606;
  font-weight: bold;
  font-size: 16px;
  min-height: 40px;
  width: 100%;
}

span.adminClient_datepicker.ant-calendar-picker
  span.ant-calendar-picker-input.ant-input
  .anticon.anticon-calendar.ant-calendar-picker-icon {
  color: #060606;
  margin-top: -8px;
}

.adminClient_datepicker .ant-calendar-range-picker-separator {
  color: unset;
  vertical-align: unset;
}

.adminClient_datepicker .ant-calendar-range-picker-input {
  color: #060606;
  font-weight: normal;
}

.adminClient-button {
  background-color: #41aea6;
  color: white;
  padding: 0 16px;
  border-radius: 29px;
}

.adminClient-button:hover {
  background-color: white;
  color: $base_color;
  border-color: $base_color;
}
div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header {
  text-align: center;
}

div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #41aea6;
  font-weight: bold;
}

div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body {
  text-align: center;
}

.deactivate-option-container {
  display: flex;
  justify-content: center;
}

.descriptionContainer {
  margin: 10px 40px 0px 40px;
}

.descriptionContainer .ant-input {
  border: 1px solid #41aea6;
  color: #41aea6;
  font-weight: bold;
  font-size: 14px;
}

.truncate {
  margin-left: 5px;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body .ant-calendar-picker-input.ant-input {
  background-color: white;
  color: #41aea6;
  border: 2px solid #41aea6;
  border-radius: 36px;
}

div
  div
  .ant-modal-wrap
  .ant-modal
  .ant-modal-content
  .ant-modal-body
  .ant-calendar-picker
  div
  .anticon.anticon-calendar.ant-calendar-picker-icon {
  color: #41aea6;
  line-height: 0.5;
}

div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-footer button.ant-btn {
  margin: 0 25px;
}

div div .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-footer div {
  display: flex;
  justify-content: space-evenly;
}

.admin-client-skrining-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  margin: 10px 0;
  div {
    color: $base_color;
    font-weight: bold;
  }
}

.adminGalleryWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .new-pagination {
    margin: 0 auto;
  }
}

.grayColor > td {
  color: lightgray;
}

.ant-tag {
  margin: 5px;
}

.mobileGallery {
  padding: 0;
  margin: 0;
  width: 100%;
}

.backFromGallery {
  display: none;
}

.clientLessonsDesktopTable {
  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 48px;
  }
}

.clientLessonsDesktopTable {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 130px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 80px;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    min-width: 120px;
  }
  .ant-table-thead > tr > th:nth-child(4),
  .ant-table-thead > tr > th:nth-child(5),
  .ant-table-thead > tr > th:nth-child(6) {
    min-width: 130px;
  }
  .ant-table-thead > tr > th:nth-child(7) {
    min-width: 150px;
  }
}

.clientScreeningTable {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 50px;
  }
  .ant-table-thead > tr > th:nth-child(2),
  .ant-table-thead > tr > th:nth-child(6) {
    min-width: 110px;
  }
  .ant-table-thead > tr > th:nth-child(3),
  .ant-table-thead > tr > th:nth-child(9) {
    min-width: 100px;
  }
  .ant-table-thead > tr > th:nth-child(4),
  .ant-table-thead > tr > th:nth-child(5),
  .ant-table-thead > tr > th:nth-child(8) {
    min-width: 90px;
  }
  .ant-table-thead > tr > th:nth-child(7),
  .ant-table-thead > tr > th:nth-child(10),
  .ant-table-thead > tr > th:nth-child(11) {
    min-width: 70px;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 12px 8px;
  }
}

.measurement-info-table {
  max-width: 1000px;

  .ant-table {
    overflow-x: unset;
  }

  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 48px;
  }
}

@media screen and (min-width: 768px) {
  .clientMobileView {
    display: none;
  }
  .mobileOnly {
    display: none !important;
  }
  .next_back_buttons {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .ant-table {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 900px) {
  .ant-dropdown {
    width: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  .clientDesktopView {
    display: none;
  }

  .desktopOnly {
    display: none !important;
  }

  .goBack {
    color: $base_color;
    position: relative;
    cursor: pointer;
  }
  .goBack > .anticon.anticon-left {
    height: 11px;
  }

  .goBack > span {
    padding-left: 20px;
    font-size: 11px;
  }

  .flexLayout {
    display: flex;
    margin: unset;
    flex-wrap: wrap;
  }

  .admin-client-panel .clientInfo {
    &-title {
      margin: unset !important;
      padding-right: 5px;
      font-size: 12px;
      line-height: 25px;
      font-weight: normal;
    }
    &-data {
      margin: unset !important;
      font-size: 12px;
      font-weight: normal;
      line-height: 25px;
    }
  }

  .admin-client-panel .admin-client-info {
    padding: 0;
  }

  .admin-client-panel .admin-client-info-data {
    padding: 0;
  }

  .mobileOtherInfo {
    margin-top: 15px;
    padding-left: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #808080;
  }

  .otherInfo {
    &-img {
      padding-right: 10px;
      line-height: 30px;
    }
    &-title {
      color: $base_color;
      font-weight: bold;
      line-height: 30px;
      font-size: 14px;
    }
    &-data {
      padding-left: 5px;
      line-height: 30px;
      font-size: 14px;
    }
    &-data-region {
      padding-left: 0;
    }
  }

  .count-row {
    display: flex;
    padding-top: 10px;
    &-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: unset;
      margin-right: auto;

      &-value {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 31px;
        height: 31px;
        border-radius: 50% 50%;
        background: $base_color;
        color: white;
        margin-right: 11px;
        font-weight: 800;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .clientMobileButton {
    margin-top: 20px !important;
  }

  .activateButton {
    justify-content: center !important;
    // > div {
    //   background: $base_color;
    //   color: white !important;
    // }
  }

  .admin-main {
    &-clientProfile {
      display: unset;
      justify-content: unset;
      &-inner {
        min-width: unset;
        width: unset;
        max-width: unset;
      }
    }
  }

  .profileHidden {
    display: none !important;
  }

  .admin-client-panel {
    width: unset;
    padding: unset;
    padding-top: 10px;
    color: unset;
    * {
      text-align: unset;
    }
    &-mobileContainer {
      display: flex;
      margin-top: 20px;
      &-inner {
        margin: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .admin-client-panel .admin-client {
    &-photo {
      margin: 0 20px 0 0;
      img {
        margin: unset;
        width: 100px !important;
        height: 100px !important;
        border: none;
      }
    }
    &-name {
      font-size: 1rem;
      text-align: left;
      color: #333333 !important;
      margin: unset;
    }
    &-age {
      text-align: left;
      color: $base_color;
      font-size: 14px;
      font-weight: bold;
      margin: unset;
      margin-top: 10px;
    }
    &-date {
      color: $base_color;
    }
  }

  .tabs {
    padding-bottom: 5px;
  }

  .clientMobileView {
    display: block;
    //min-height: calc(100vh - 50px);
    background-color: white;

    .screening-header {
      height: 45px;
      line-height: 45px;
      font-family: Montserrat, serif;
      font-weight: bold;
      font-size: 12px;
      color: $base_color;
      display: flex;
      justify-content: space-evenly;
      text-align: left;
      margin-top: 10px;

      &-screening {
        width: 40%;
      }

      &-plan {
        width: 28%;
      }

      &-completed {
        width: 32%;
      }
    }

    .screening-line {
      display: flex;
      justify-content: space-evenly;
      height: 45px;
      align-items: center;
      font-size: 11px;
      border-bottom: 1px solid $base_color;

      &-title {
        width: 40%;
      }

      &-plan {
        width: 28%;
      }

      &-completed {
        width: 32%;
        display: flex;
        color: $base_color;

        .icon {
          position: relative;
          top: -2px;
          color: $base_color;
          padding-left: 30%;
        }
      }
    }

    .measurement-header {
      height: 20px;
      line-height: 16px;
      font-family: Montserrat, serif;
      font-weight: bold;
      font-size: 12px;
      color: $base_color;
      display: flex;
      justify-content: space-evenly;
      text-align: left;
      margin-top: 10px;

      &-date {
        width: 30%;
      }

      &-createdBy {
        width: 38%;
      }

      &-show {
        width: 32%;
      }
    }

    .measurement-content {
      display: flex;
      justify-content: space-evenly;
      height: 45px;
      align-items: center;
      font-size: 12px;
      //border-bottom: 1px solid $base_color;

      &-date {
        width: 30%;
      }

      &-createdBy {
        width: 38%;
      }

      &-show {
        width: 32%;
        color: $base_color;
        text-align: right;

        .icon {
          vertical-align: 0;
          padding-left: 5px;
        }
      }
    }

    .measurements-container {
      padding-top: 10px;
      padding-bottom: 60px;

      .measurementTitleFirst {
        text-align: center;
      }

      .measurementTitle {
        text-align: center;
      }

      .noMeasurementContainer {
        text-align: center;
      }
    }

    .screeningsContainer {
      padding-top: 10px;
    }

    .adminClient-button {
      font-size: 12px;
    }

    .infoContainer {
      padding: 10px 0 30px;
      &-topBar {
        display: flex;
        justify-content: space-between;
        &-sectionsDropdown {
          color: $base_color;
          font-size: 11px !important;
        }
      }
      &-sectionHeader {
        color: $base_color;
        font-weight: bold;
        border-bottom: 1px solid $base_color;
        margin-bottom: 15px;
      }
      &-subsectionHeader {
        color: $base_color;
        margin-bottom: 10px;
      }
      &-title {
        color: #bdbdbd;
        padding-bottom: 5px;
      }
      &-data {
        color: #333333;
        padding-bottom: 10px;
      }
    }

    .mobileGallery {
      padding: 50px 20px;
      min-height: 100svh;
    }

    .backFromGallery {
      display: block;
      padding-top: 10px;
    }

    .backFromGallery > .anticon.anticon-left {
      top: 14px;
    }

    .unfiltered {
      display: none;
    }

    .adminGalleryWrapper {
      justify-content: center;
      padding-top: 10px;
    }

    .imageGalleryMain {
      justify-content: center;
    }

    .ant-pagination.pagination a {
      font-size: 16px;
    }

    .ant-pagination.pagination.mini {
      padding-bottom: 80px;
    }
    .ant-pagination.pagination.mini .anticon {
      font-size: 16px;
    }

    .lessonsMobileView {
      .container {
        .backFromLessons {
          margin: 10px 15px 0;
        }

        &-inner {
          padding-top: 10px;

          &-options {
            display: flex;
            justify-content: space-between;

            &-rangePicker {
              width: 55%;
            }

            &-monthPicker {
              display: flex;
              align-items: center;
              color: $base_color;
              width: 30%;
              margin-right: 10px;

              &-icon {
                position: relative;
                top: -2px;
              }

              &-text {
                text-align: center;
              }
            }

            &-addAction {
              width: 15%;
            }
          }

          &-toDoList {
            margin: 20px 15px;
            display: flex;
            flex-direction: column;

            &-toDo {
              border-bottom: 1px solid $base_color;
              padding: 10px 0;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;

              &-date {
                flex: 30%;
              }

              &-time {
                flex: 20%;
                color: $base_color;
              }

              &-title {
                flex: 45%;
              }

              &-arrow {
                flex: 5%;
                color: $base_color;
                position: relative;
                top: -2px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 975px) {
  .submenu {
    padding: 32px 8px;
    gap: 8px;

    ul {
      gap: 8px;
    }

    ul li a {
      padding: 0;
    }
  }
}

@media screen and (min-width: 975px) and (max-width: 1075px) {
  .submenu {
    padding: 32px 16px;

    ul li a {
      padding: 0;
    }
  }
}

@media screen and (min-width: 1300px) {
  .submenu {
    padding: 32px;
    gap: 32px;

    ul {
      gap: 32px;
    }
  }

  .admin-main-clientProfile-inner {
    max-width: 260px;
  }
}

@media screen and (min-width: 1500px) {
  .admin-main-clientProfile-inner {
    max-width: 275px;
  }
}

@media screen and (min-width: 1800px) {
  .admin-main-clientProfile-inner {
    max-width: 300px;
  }
}

.admin-client-screenings {
  margin: 20px 0;
}
