.action-answers-td {
  text-align: center;
  font-weight: bold;
  padding: 0;
  & > div {
    & > div {
      text-align: center;
      font-weight: bold;
    }
  }
}
