@import '../../../config';

.disabled {
  pointer-events: none;
}

.divider {
  border: 1px solid $base_color;
}

.warning {
  text-align: center;

  &-text {
    color: red;
  }
}
