@import './config';

html {
  font-size: 100%;
  background-color: white;
  scroll-behavior: smooth;
  background-color: #272928;
  width: 100%;
  overflow: auto;
  height: 100%;
}

// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     min-width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: initial !important;
  height: 100%;
}

.content {
  position: relative;
  top: 3.5rem;
}

button:disabled {
  opacity: 0.5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid white;
  -webkit-text-fill-color: gray;
  box-shadow: 0 1 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* reset cursor for antd Slider */
/* sanitize.css sets cursor: not-allowed for [aria-disabled] */
.ant-slider-handle[aria-disabled] {
  cursor: pointer;
}

.galleryImage {
  img {
    margin-top: 15px;
    width: 200px;
  }
}

.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}
.defaultBackground {
  padding: 40px;
  // background: url(/images/02_bg_black&white.png) no-repeat center center fixed;
  // background-size: cover;
  // position: relative;
  // height: -webkit-fill-available;

  background-color: $backgroundColor;
  background-size: cover;
  //
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  //

  position: relative;
  height: -webkit-fill-available;
}

.adminShadow {
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.new-table {
  background-color: white;
  border: 1px solid $backgroundColor;
  border-radius: 12px;
  overflow: hidden;
}

.new-table tbody tr {
  cursor: pointer;
}

.new-table tr th {
  font-weight: 900;
  background-color: white;
  color: $textDark;
  border-bottom: 1px solid $backgroundColor;
}

.new-table tr td {
  font-weight: normal;
  color: $textDark;
  border-top: 1px solid $backgroundColor;
  border-bottom: 1px solid $backgroundColor;
}

.new-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 16px;
}

.new-table .ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
  font-weight: 900;
  font-size: 12px;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item a,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-prev a,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-next a,
.new-table .ant-pagination-jump-prev,
.new-table .ant-pagination-jump-next {
  color: $textDark;
  border: 1px solid $textDark;
  border-radius: 50%;
}

.new-table .ant-pagination-prev i,
.new-table .ant-pagination-next i {
  vertical-align: 1.5px;
}

.new-table .ant-pagination-item,
.new-table .ant-pagination-prev,
.new-table .ant-pagination-next {
  border: none;
  border-radius: 50%;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-prev,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-next {
  opacity: 0.3;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:hover,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:focus,
.new-table .ant-pagination-prev:hover:not(.ant-pagination-disabled),
.new-table .ant-pagination-next:hover:not(.ant-pagination-disabled),
.new-table .ant-pagination-prev:focus,
.new-table .ant-pagination-next:focus {
  font-weight: 900;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:hover,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-prev a:hover,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-next a:hover {
  color: $textDark;
  background-color: $secondaryHover;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:hover a {
  color: $textDark;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item-active a,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-item-active a:hover {
  font-weight: 900;
  color: white;
  background-color: $base_color;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-prev a:hover,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-next a:hover {
  background-color: white;
}

.new-table .ant-pagination-jump-prev,
.new-table .ant-pagination-jump-next {
  line-height: 31px;
}

.new-table .ant-pagination-jump-prev .ant-pagination-item-container,
.new-table .ant-pagination-jump-next .ant-pagination-item-container {
  border: none;
  border-radius: 50%;
}

.new-table .ant-pagination-jump-prev .ant-pagination-item-container:hover,
.new-table .ant-pagination-jump-next .ant-pagination-item-container:hover {
  color: $textDark;
  background-color: $secondaryHover;
}

.new-table .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.new-table .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: $textDark;
}

.new-table .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.new-table .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $textDark;
  vertical-align: 0;
}

.new-table .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.new-table .ant-pagination-next i {
  margin-left: 1px;
}

.new-table-control-button {
  min-width: 125px;
  height: 40px;
}

.new-table-control-checkbox {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
  padding: 8px 16px;
  border: 1px solid #060606;
  border-radius: 20px;
  cursor: pointer;

  .ant-checkbox-wrapper {
    font-size: 16px;
    color: #060606;
  }

  .ant-checkbox + span {
    padding-right: 0;
  }
}

.new-table-control-checkbox.ant-checkbox-wrapper {
  font-size: 16px;
  color: #060606;
}

.new-pagination.ant-pagination {
  float: none;
  text-align: center;
  font-weight: 900;
  font-size: 12px;
}

.new-pagination.ant-pagination .ant-pagination-item a,
.new-pagination.ant-pagination .ant-pagination-prev a,
.new-pagination.ant-pagination .ant-pagination-next a,
.new-pagination .ant-pagination-jump-prev,
.new-pagination .ant-pagination-jump-next {
  color: $textDark;
  border: 1px solid $textDark;
  border-radius: 50%;
}

.new-pagination .ant-pagination-prev i,
.new-pagination .ant-pagination-next i {
  vertical-align: 1.5px;
}

.new-pagination .ant-pagination-item,
.new-pagination .ant-pagination-prev,
.new-pagination .ant-pagination-next {
  border: none;
  border-radius: 50%;
}

.new-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-prev,
.new-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-next {
  opacity: 0.3;
}

.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:hover,
.new-table .ant-table-pagination.ant-pagination .ant-pagination-item:focus,
.new-table .ant-pagination-prev:hover:not(.ant-pagination-disabled),
.new-table .ant-pagination-next:hover:not(.ant-pagination-disabled),
.new-table .ant-pagination-prev:focus,
.new-table .ant-pagination-next:focus {
  font-weight: 900;
}

.new-pagination.ant-pagination .ant-pagination-item:hover,
.new-pagination.ant-pagination .ant-pagination-prev a:hover,
.new-pagination.ant-pagination .ant-pagination-next a:hover {
  color: $textDark;
  background-color: $secondaryHover;
}

.new-pagination.ant-pagination .ant-pagination-item:hover a {
  color: $textDark;
}

.new-pagination.ant-pagination .ant-pagination-item-active a,
.new-pagination.ant-pagination .ant-pagination-item-active a:hover {
  font-weight: 900;
  color: white;
  background-color: $base_color;
}

.new-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-prev a:hover,
.new-pagination.ant-pagination .ant-pagination-disabled.ant-pagination-next a:hover {
  background-color: white;
}

.new-pagination .ant-pagination-jump-prev,
.new-pagination .ant-pagination-jump-next {
  line-height: 31px;
}

.new-pagination .ant-pagination-jump-prev .ant-pagination-item-container,
.new-pagination .ant-pagination-jump-next .ant-pagination-item-container {
  border: none;
  border-radius: 50%;
}

.new-pagination .ant-pagination-jump-prev .ant-pagination-item-container:hover,
.new-pagination .ant-pagination-jump-next .ant-pagination-item-container:hover {
  color: $textDark;
  background-color: $secondaryHover;
}

.new-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.new-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: $textDark;
}

.new-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.new-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $textDark;
  vertical-align: 0;
}

.new-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.new-pagination .ant-pagination-next i {
  margin-left: 1px;
}

.table {
  background-color: white;
  border-radius: 40px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.table .ant-table-placeholder {
  border-radius: 0 0 40px 40px;
}

.table tr th {
  font-weight: 900;
  background-color: white;
  border-bottom-color: gray;
}

.table tr td {
  font-weight: 900;
  border-bottom-color: $base_color;
}

.table tbody tr {
  color: $base_color;
  cursor: pointer;
}

.table .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 40px;
}

.table .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 40px;
}

.table .ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
}

.table .ant-table-pagination.ant-pagination .ant-pagination-item,
.table .ant-table-pagination.ant-pagination .ant-pagination-prev a,
.table .ant-table-pagination.ant-pagination .ant-pagination-next a,
.table .ant-pagination-prev,
.table .ant-pagination-next {
  border: none;
  border-radius: 0;
}

.table .ant-table-pagination.ant-pagination .ant-pagination-item-active,
.table .ant-table-pagination.ant-pagination .ant-pagination-item:hover,
.table .ant-table-pagination.ant-pagination .ant-pagination-item:focus,
.table .ant-pagination-prev:hover:not(.ant-pagination-disabled),
.table .ant-pagination-next:hover:not(.ant-pagination-disabled),
.table .ant-pagination-prev:focus,
.table .ant-pagination-next:focus {
  font-weight: 900;
  border-bottom: 2px solid $base_color;
}

.table .ant-pagination-prev i,
.table .ant-pagination-next i {
  font-size: 20px;
  vertical-align: baseline;
}

.table .ant-table-pagination.ant-pagination a {
  color: $base_color;
}

.table .ant-table-pagination.ant-pagination .ant-pagination-disabled a {
  color: gray;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-modal-footer .ant-btn {
  min-height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  color: #060606;
  border: 1px solid #060606;
  background-color: white;
  font-size: 16px;

  &:hover {
    background-color: #d6fcf9;
    color: #060606;
    border-color: #060606;
  }

  &-primary {
    color: white;
    border: 1px solid #41aea5;
    background-color: #41aea5;

    &:hover {
      border-color: #2b8c84;
      background-color: #2b8c84;
      color: white;
    }
  }
}

a {
  color: #41aea6;
}

.flex {
  display: flex;
}

h3 {
  margin-top: 20px;
  font-size: 16px;
  color: #41aea6;
  font-weight: bold;
}

h2 {
  color: #41aea6;
  font-weight: bold;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  background: none;
  font-weight: bold;
  font-size: 19px;
  border-color: none;
  border-bottom: none;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #41aea6;
  background: none;
  font-weight: bold;
  font-size: 19px;
  border-color: none;
  border-bottom: 2px solid #41aea6;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background: none;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #41aea6;
}

.tablePhoto {
  img {
    height: 50px;
    border-radius: 50%;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #41aea6;
  border-color: #41aea6;
}

.pointer {
  cursor: pointer;
}

.table-control-dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #060606;
  background-color: white;
  border: 1px solid #091817;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;

  &.deactivation-specification {
    border: 2px solid #41aea6;
    width: 217px;
    margin-top: 10px;
    height: 38px;
  }
}

.dropdown-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dropdown-deactivate {
    color: #41aea6;
    font-weight: bold;
    margin-left: -3px;

    &.dropdown-placeholder {
      color: gray;
      opacity: 0.3;
    }
  }
}

.dropdown-icon-container {
  margin-left: auto;

  &.icon-deactivate {
    color: #41aea6;
  }
}

.icon-deactivate {
  filter: invert(85%) sepia(21%) saturate(533%) hue-rotate(125deg) brightness(85%) contrast(83%);
}

@media screen and (max-width: 768px) {
  .ant-modal-footer .ant-btn {
    font-size: 14px;
  }
}
