@import '../../config';

.ant-pagination.pagination {
  font-weight: bolder;
}

.paginationDiv {
  width: 100%;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
}

.clientGalleryWrapper {
  position: relative;
  top: 100px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  .galleryEmpty {
    margin-left: 20px;
  }
}

.ant-pagination.pagination .anticon {
  color: $base_color;
  font-size: 1.5em;
}

.ant-pagination.pagination a {
  color: $base_color;
  font-size: 20px;
}

.ant-pagination.pagination .ant-pagination-item {
  margin-top: 1.5vh;
}
