@import '../../config';

.showJustOnMobile {
  display: none;
}

.images-list div {
  margin-top: 10px;
}

.activities-main {
  padding: 40px;
  padding-top: 120px;
  min-height: 100vh;
}

.activities-form {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: flex-start;
}

.activities-form-desktop {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: flex-end;
}

.activities-searchField-container-desktop {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.activities-searchField-container {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 350px - 120px);
  .activities-input {
    margin-bottom: 10px;
  }
}

.activities-searchField-group-desktop {
  display: flex;
  gap: 16px;
}

.activities-searchField-desktop {
  width: 250px;
}

.activities-searchField {
  position: relative;
  width: fit-content;
  margin-right: 15px;
}

.activities-searchField > svg {
  position: absolute;
  top: 12px;
  right: 15px;
  color: gray;
}

.activities-searchField > input {
  padding-left: 40px;
}

.activity-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  &-row {
    display: flex;
    gap: 16px;
    .admin-searchButton:nth-of-type(2) {
      margin-left: 10px;
    }
    &-checkbox {
      display: flex;
      align-items: center;
    }
  }
}

.ant-input.activities-input {
  height: 40px;
  width: 200px;
  padding-left: 20px;
  border: 2px solid $base_color;
  border-radius: 24px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1rem;
  font-weight: bold;
  color: gray;
}

.number-input {
  height: 40px;
  max-width: 125px;
  padding-left: 20px;
  border: 2px solid $base_color;
  border-radius: 24px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.25rem;
  font-weight: bold;
  color: gray;
  margin-right: 13px;
}

.activities-name {
  height: 40px;
  max-width: 180px;
  padding-left: 20px;
  border: 2px solid $base_color;
  border-radius: 24px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.25rem;
  font-weight: bold;
  color: gray;
  margin-right: 1px;
}

.activities-code {
  height: 40px;
  max-width: 82px;
  padding-left: 15px;
  border: 2px solid $base_color;
  border-radius: 24px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.25rem;
  font-weight: bold;
  color: gray;
}

.activities-label {
  margin-left: 20px;
  font-size: 18px;
}

.activities-form .actionsDropdown {
  display: inline-block;
  margin-right: 25px;
}

.activitiesDropdown {
  padding-top: 12px;
}

iframe {
  margin-right: 10px;
  margin-bottom: 10px;
  border: 0;
}

.activities-table {
  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 48px;
  }
}

.admin-activityDetail-buttons {
  display: flex;
  gap: 32px;
}

@media screen and (max-width: 768px) {
  .activitiesMobile {
    background-color: $backgroundColorMobile;
    .activitiesMobile-box {
      width: 100%;
      margin: unset;
      .activitiesMobile-main {
        padding: 70px 20px 20px 20px;
        background-color: $backgroundColorMobile;
        .activitiesMobile-form {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: unset;
          padding-bottom: 10px;
          .activitiesMobile-searchField-container {
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            .activitiesMobile-searchField {
              flex-basis: 48%;
              margin-right: unset;
              .activitiesMobile-input {
                max-width: unset;
                width: 100%;
                margin-bottom: 15px;
              }
            }
          }
          .activitiesMobile-button {
            padding-bottom: 15px;
          }
        }
        .activitiesMobile-activityName {
          font-weight: bold;
        }
      }
    }
  }

  .admin-activityDetail {
    display: flex;
    flex-direction: column;
    &-header {
      display: flex !important;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      &-backIcon {
        display: flex;
        align-items: center;
        color: $base_color;
        font-size: 14px;
        font-weight: normal;
        icon {
          top: -2px;
        }
      }
    }
    &-headingText {
      text-align: start;
      padding: unset;
      margin-top: 30px;
      text-decoration: underline;
    }
    &-buttons {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      button {
        display: block !important;
      }
      button:first-of-type {
        margin-right: 5px;
      }
    }
  }
  .doNotShowOnMobile {
    display: none;
  }
  .showJustOnMobile {
    display: block;
  }
  .ant-modal-footer > div {
    display: flex;
  }
  iframe:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 930px) {
  .activities-searchField-desktop {
    width: 225px;
  }
}

@media screen and (max-width: 875px) {
  .activities-searchField-desktop {
    width: 200px;
  }
}
