.ant-input.ant-input-outlined,
.ant-picker.ant-picker-outlined,
.ant-input-password.ant-input-outlined {
  border: 2px solid #41aea6;
  border-radius: 36px;
  color: #000000;
  height: 42px;
  font-size: 20px;
  width: 100%;
}

.ant-input.ant-input-outlined:hover,
.ant-input.ant-input-outlined:active,
.ant-picker.ant-picker-outlined:hover,
.ant-picker.ant-picker-outlined:active,
.ant-input-password.ant-input-outlined:hover,
.ant-input-password.ant-input-outlined:active {
  border: 2px solid #41aea6;
  color: black;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #41aea6;
  font-size: 14px;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
  color: #9999a0;
  font-size: 14px;
}

.ant-input.ant-input-outlined::placeholder,
.ant-input-outlined > input::placeholder {
  font-family: 'Montserrat', arial;
  color: #9999a0;
  font-weight: bold;
  font-size: 16px !important;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}
