@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

html {
  --antd-wave-shadow-color: $base_color;
}
.inline {
  display: flex;
}
.checkBox {
  display: inline-block;
}
.outter {
  border: 2px solid #41aea6;
  border-radius: 50%;
  background: white;
  height: 17px;
  width: 17px;
}

.inner {
  border-radius: 50%;
  background-color: white;
  width: 5px;
  height: 5px;
  padding: 3.5px;
  margin: 0 auto;
  border: 1px solid white;
  margin: 2px;
}

.innerActive {
  background-color: $base_color;
  border: 1px solid #41aea6;
}
