@import '../config';

.modal {
  padding: 0 5%;

  .ant-modal-body {
    padding: 10px;
  }
  .ant-modal-content {
    border-radius: 22px;
  }
}

.textSuccess {
  font-family: 'Montserrat', arial;
  font-size: 18px;
  color: $base_color;
  text-align: center;
  font-weight: bold;
  margin: 0 0 0 0;
}

.textFail {
  font-family: 'Montserrat', arial;
  font-size: 18px;
  color: #cf2b31;
  text-align: center;
  font-weight: bold;
  margin: 0 0 0 0;
}
