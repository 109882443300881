@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.ant-btn.admin-searchButton {
  background-color: $base_color;
  color: white;
  padding: 0 16px;
  float: right;
  border-radius: 29px;
  font-size: 19px;
}

.activities-main.users-activities-main {
  padding-top: 120px;
}

.user-role-dropdown {
  width: 200px;
  font-weight: bold;
}

.userMobileList-item {
  display: none;
}

.users-table {
  .ant-table-thead > tr > th:nth-child(1) {
    padding-right: 0;
    width: 66px;
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    padding-right: 0;
  }

  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 68px;
  }

  .inactiveTextColor > td {
    color: $textGray;

    .icon {
      color: $textGray;
    }

    .tablePhoto {
      img {
        filter: grayscale(100%) brightness(1.3) saturate(0.5);
      }
    }
  }
}

.users-table-mobile {
  display: none;
}

.ikona.pridatButton.usersAddButton {
  display: none;
}

.warning-popup {
  i {
    color: #d13c3c;
  }

  .popup {
    color: #d13c3c;
    border: 1px solid #d13c3c;

    &:before {
      border-left: 1px solid #d13c3c;
      border-bottom: 1px solid #d13c3c;
    }
  }
}

.info-popup {
  i {
    color: #484848;
  }

  .popup {
    color: #484848;
    border: 1px solid #484848;

    &:before {
      border-left: 1px solid #484848;
      border-bottom: 1px solid #484848;
    }
  }
}

.bubble-popup {
  position: relative;
  display: inline-block;
  margin-left: 10px;

  i {
    vertical-align: middle;
  }

  .popup {
    display: none;
    position: absolute;
    // top: -82px;
    bottom: 25px;
    transform: translateX(-50%);
    width: 250px;
    background: white;
    border-radius: 5px;
    font-size: 11px;
    z-index: 1;
    & .popup-scroll {
      overflow-y: auto;
      max-height: 120px;
      padding: 10px;

      & > p {
        margin: 0;
      }

      & > p + p {
        border-top: 1px solid rgba(209, 60, 60, 0.3);
        margin-top: 5px;
        padding-top: 5px;
      }
    }

    display: none;
    position: absolute;
    // top: -82px;
    bottom: 25px;
    transform: translateX(-50%);
    width: 250px;
    background: white;
    border-radius: 5px;

    font-size: 11px;
    z-index: 1;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;

      z-index: -1;
      bottom: -7px;
      left: 50%;
    }
  }

  &:hover {
    .popup {
      display: block;
    }
  }
}

.users-search-bar {
  width: 300px;
}

.table-controls-group {
  display: flex;
  gap: 32px;
}

.table-controls-mobile {
  display: none;
}

.users-table-control-button {
  font-size: 16px;
  width: 125px;
}

@media screen and (max-width: 768px) {
  .only-desktop {
    display: none;
  }

  .ant-btn.admin-searchButton {
    display: none;
  }

  // .adminDropdown {
  //   display: none;
  // }
  .usersDropdownLabel {
    display: none;
  }

  .usersDropdownValue {
    font-size: 16px !important;
    line-height: 21px;
    display: flex !important;
    font-weight: normal;

    i {
      margin-left: 5px;
      height: 10px;
      line-height: unset;
    }
  }

  .ikona.pridatButton.usersAddButton {
    display: flex;
    margin: unset;
  }

  .activities-form.usersForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 15px;
  }

  .userMobileList {
    width: 100%;
    padding: 20px 5px 64px;
  }

  .usersMobileList-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-leftCont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-tablePhoto {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;

        img {
          height: 50px;
          border-radius: 50%;
        }
      }

      &-userInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;

        &-name {
          color: #333333;
          font-weight: normal;
          padding-bottom: 2px;
          margin: 0px 0px;
        }

        &-city {
          color: #808080;
          font-style: normal;
          font-weight: normal;
          margin: 0px 0px;
        }
      }
    }

    &-rightCont {
      display: flex;
      align-items: center;

      &-role {
        color: $base_color;
        font-size: 12px;
        line-height: 21px;
        font-weight: bold;
        padding-right: 7px;
        margin: 0px 0px;
      }

      &-icon {
        color: $base_color;
      }
    }
  }

  .ant-table-small {
    border-radius: 40px;
  }

  .ant-input.activities-input.users-input {
    height: 35px;
    width: 100px;
    padding-left: unset;
    border: unset;
    border-bottom: 1px solid $base_color;
    border-radius: 0px;
    box-shadow: unset;
    font-size: unset;
    font-weight: unset;
    color: unset;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: normal;
    color: $base_color;

    &::placeholder {
      color: $base_color;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .activities-main.users-activities-main {
    padding: unset;
    padding-top: 64px;
    min-height: 100vh;
  }

  .clientsBox.usersClientsBox {
    width: 100%;
    background-color: white;
  }

  .users-table {
    display: none;
  }

  .users-table-mobile {
    display: block;
    padding-bottom: 10px;
  }

  .users-table-mobile th {
    display: none;
  }

  .table-controls-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: lightgray;
  margin-left: 5px;
}

.online {
  background-color: green;
}

.idle {
  background-color: yellow;
}

.offline {
  background-color: lightgray;
}

@media screen and (max-width: 1100px) {
  .table-controls-group {
    gap: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .users-search-bar {
    width: 250px;
  }
}

@media screen and (max-width: 900px) {
  .users-search-bar {
    width: 200px;
  }

  .table-controls-group {
    gap: 8px;
  }
}

@media screen and (max-width: 800px) {
  .users-search-bar {
    width: 190px;
  }

  .user-role-dropdown {
    width: 190px;
  }

  .users-table-control-button {
    width: 100px;
  }
}
